import { FacebookFilled } from "@ant-design/icons";
import { Box, Button, Heading, Stack } from "@chakra-ui/react";
import moment from "moment";
import { Link as ReachLink } from "react-router-dom";
import PublicRecordTable from "../components/record/PublicRecordTable";

const PublicRecordPage = () => {
  return (
    <Box height={"100vh"} display={"flex"} flexDirection={"column"}>
      <Heading textColor={"gray.600"} size={"xl"} align="center" my={10}>
        {moment().year()} 生命教育音樂劇
      </Heading>
      <Box textAlign="right" mx={10} mb={5}>
        <Button
          as={ReachLink}
          to="/dashboard"
          colorScheme={"brand"}
          variant="outline"
          size="sm"
        >
          登錄場次
        </Button>
      </Box>
      <Box mx={10}>
        <PublicRecordTable />
      </Box>
      <Box
        backgroundColor={"gray.600"}
        color={"gray.200"}
        minHeight={100}
        align={"center"}
        marginTop={"auto"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack>
          <Box>
            <a href="https://bit.ly/2WQmurw">
              <FacebookFilled style={{ fontSize: 20 }} />
            </a>
          </Box>
          <Box>
            {moment().year()} {process.env.REACT_APP_COMPANY_NAME} All Rights
            Reserved ©
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default PublicRecordPage;
