import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link as ReachLink, useHistory } from "react-router-dom";
import api from "../api";
import { isNumberRegx } from "../utils";

function Signup() {
  const history = useHistory();
  const mutation = useMutation(({ email, password }) =>
    api.Auth.signup(email, password)
  );
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const {
    handleSubmit,
    formState: { errors },
    register,
    formState,
    watch,
  } = useForm();

  const password = useRef({});
  password.current = watch("password", "");

  function onSubmit(values) {
    mutation.mutate({
      email: values.email,
      password: values.password,
    });
  }

  if (mutation.isSuccess) {
    history.push("/login");
  }

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} minW={"400px"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>註冊</Heading>
        </Stack>
        <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={errors.email}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  placeholder="email"
                  {...register("email", { required: "請填寫正確的email" })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={errors.password}>
                <FormLabel htmlFor="password">密碼</FormLabel>
                <InputGroup>
                  <Input
                    name="password"
                    placeholder="password"
                    type={show ? "text" : "password"}
                    {...register("password", {
                      required: "請填寫密碼",
                      minLength: {
                        value: 8,
                        message: "至少要有八個字元",
                      },
                      validate: {
                        number: (v) =>
                          isNumberRegx.test(v) || "至少要有一個數字",
                      },
                    })}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText>至少要有8個字元（包含1個數字）</FormHelperText>
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="password_repeat"
                isInvalid={errors.password_repeat}
              >
                <FormLabel htmlFor="password_repeat">再次輸入密碼</FormLabel>
                <Input
                  name="password_repeat"
                  type="password"
                  placeholder="password"
                  {...register("password_repeat", {
                    validate: (value) =>
                      value === password.current || "密碼不一樣",
                  })}
                />
                <FormErrorMessage>
                  {errors.password_repeat && errors.password_repeat.message}
                </FormErrorMessage>
              </FormControl>
              <Stack spacing={10}>
                <Button
                  color="white"
                  bg="brand.600"
                  isLoading={formState.isSubmitting || mutation.isLoading}
                  type="submit"
                  _hover={{
                    bg: "brand.500",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  註冊
                </Button>
                {mutation.isError ? (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>
                      {mutation.error.response
                        ? mutation.error.response.data.detail
                        : mutation.error.message}
                    </AlertDescription>
                  </Alert>
                ) : null}
              </Stack>
            </Stack>
          </form>
        </Box>
        <Box textAlign="center">
          <Link as={ReachLink} to="/">
            回首頁
          </Link>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Signup;
