import { Button, DatePicker, Form, Space, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { useRecords } from "../../hooks/records";
import { dateFormat } from "../../utils";

const { RangePicker } = DatePicker;

const PublicRecordTable = () => {
  const { records, loading } = useRecords();
  const [start, setStartData] = useState(undefined);
  const [end, setEndData] = useState(undefined);

  const onFilterDate = (values) => {
    setStartData(values["date"][0]);
    setEndData(values["date"][1]);
  };

  console.log(start);
  console.log(end);

  const onReset = () => {
    setStartData(undefined);
    setEndData(undefined);
  };

  const getOptions = (arr, key) => {
    return [...new Set(arr.map((d) => d[key]))]
      .filter((d) => (d !== null) & (d !== ""))
      .sort()
      .map((d) => {
        return { text: d, value: d };
      });
  };

  const onFilter = (key) => {
    return (value, record) => {
      if (record[key]) {
        return record[key] === value;
      }
    };
  };

  const office = getOptions(records, "office");
  const troupe = getOptions(records, "troupe");
  const play = getOptions(records, "play");
  const location_type = getOptions(records, "location_type");

  const columns = [
    { key: "id", dataIndex: "key", title: "No" },
    {
      key: "office",
      dataIndex: "office",
      title: "所屬區辦",
      filters: office,
      onFilter: onFilter("office"),
    },
    {
      key: "troupe",
      dataIndex: "troupe",
      title: "劇團名稱",
      filters: troupe,
      onFilter: onFilter("troupe"),
    },
    {
      key: "play",
      dataIndex: "play",
      title: "戲劇名稱",
      filters: play,
      onFilter: onFilter("play"),
    },
    {
      key: "location",
      title: "演出地點",
      render: (text, record) => {
        return (
          <div>{record.is_public ? <div>{record.location}</div> : null}</div>
        );
      },
    },
    {
      key: "location_type",
      title: "地點類型",
      render: (text, record) => {
        return (
          <div>
            {record.is_public ? <div>{record.location_type}</div> : null}
          </div>
        );
      },
      filters: location_type,
      onFilter: onFilter("location_type"),
    },
    {
      key: "address",
      title: "地址",
      render: (text, record) => {
        return (
          <div>
            {record.is_public ? (
              <div>
                {record.address_city}
                {record.address_site}
                {record.address_street}
              </div>
            ) : null}
          </div>
        );
      },
    },
    { key: "date", dataIndex: "date", title: "演出日期" },
    {
      key: "time",
      title: "演出時間",
      render: (text, record) => {
        return (
          <div>
            {record.start_time} ~ {record.end_time}
          </div>
        );
      },
    },
    {
      key: "is_public",
      title: "開放觀賞",
      render: (text, record) =>
        record.is_public ? (
          <div>開放觀賞</div>
        ) : (
          <div>演出場地不接受外賓觀賞，敬請見諒！</div>
        ),
      filters: [
        {
          text: "開放觀賞",
          value: true,
        },
        {
          text: "演出場地不接受外賓觀賞，敬請見諒！",
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_public === value,
    },
  ];

  return (
    <>
      <Form onFinish={onFilterDate} style={{ marginBottom: 10 }}>
        <Space>
          <Form.Item
            noStyle
            name="date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <RangePicker
              format={dateFormat}
              allowClear={false}
              ranges={{
                這個月: [moment().startOf("month"), moment().endOf("month")],
              }}
            />
          </Form.Item>
          <Button htmlType="submit">搜尋</Button>
          <Button type="default" onClick={onReset}>
            重設
          </Button>
        </Space>
      </Form>
      <Table
        columns={columns}
        dataSource={records
          .map((d, i) => {
            d.key = i;
            return d;
          })
          .filter((i) =>
            start && end
              ? i.date &&
                moment(i.date) >= start &&
                moment(i.date) <= end.add(1, "days")
              : i
          )}
        className="mt-3"
        loading={loading}
        scroll={{ x: 536 }}
      ></Table>
    </>
  );
};

export default PublicRecordTable;
