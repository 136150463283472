import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  message,
  Select,
  Typography,
} from "antd";
import { useEffect } from "react";
import { useOptions } from "../../hooks/options";
import { useUser } from "../../hooks/user";
import PhoneFormItem from "../PhoneFormItem";

const { Title } = Typography;
const { Option } = Select;

const ProfileForm = () => {
  const { user, updateUser, loading } = useUser();
  const { options: officeOptions } = useOptions({ type: "office" });
  const [form] = Form.useForm();

  const onFinish = (values) => {
    updateUser.mutateAsync({ data: values }).then(() => {
      message.success("更改成功");
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      ...user,
    });
  }, [user, form]);

  return (
    <Card>
      <Title level={4}>個人資料</Title>
      <Divider></Divider>
      <Form
        form={form}
        preserve={false}
        layout={"vertical"}
        initialValues={user}
        onFinish={onFinish}
      >
        <Form.Item
          label="姓名"
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <PhoneFormItem required={true} />
        <Form.Item label="電話" name="home_phone">
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="所屬區辦"
          name="office"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            {officeOptions.map((i) => (
              <Option value={i.name} key={i.name}></Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="劇團名稱"
          name="troupe"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input></Input>
        </Form.Item>
        <Button
          key="submit"
          htmlType="submit"
          type="primary"
          style={{ marginTop: 6 }}
          loading={loading}
        >
          存檔
        </Button>
      </Form>
    </Card>
  );
};

export default ProfileForm;
