import React, { useContext, useState } from 'react';
import {
  Box,
  Flex,
  Stack,
  Heading,
  Link,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import { message } from 'antd';
import { Link as ReachLink } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { useForm } from 'react-hook-form';

function RecoverPassword() {
  const [error, setError] = useState(null);
  const { recoverPassword, loading } = useContext(AuthContext);
  const {
    handleSubmit,
    formState: { errors },
    register,
    formState,
  } = useForm();

  function onSubmit(values) {
    recoverPassword(values.email)
      .then(({ data }) => {
        message.success(data.msg);
      })
      .catch(err => {
        setError(err);
      });
  }

  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'gray.50'}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} minW={'400px'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>寄送更改密碼連結</Heading>
        </Stack>
        <Box rounded={'lg'} bg={'white'} boxShadow={'lg'} p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={errors.email}>
                <FormLabel htmlFor="email">帳號 Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  placeholder="email"
                  {...register('email', { required: '請填寫正確的email' })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                color="white"
                bg="brand.600"
                isLoading={formState.isSubmitting || loading}
                type="submit"
                _hover={{
                  bg: 'brand.500',
                  color: 'white',
                  textDecoration: 'none',
                }}
              >
                寄送
              </Button>
              {error ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>
                    {error.response.data['detail']}
                  </AlertDescription>
                </Alert>
              ) : null}
            </Stack>
          </form>
        </Box>
        <Box textAlign="center">
          <Link as={ReachLink} to="/">
            回首頁
          </Link>
        </Box>
      </Stack>
    </Flex>
  );
}

export default RecoverPassword;
