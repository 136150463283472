import { useContext } from "react";
import { useQuery } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useOptions = ({ type }) => {
  const { token } = useContext(AuthContext);
  const key = `${type}_options`;
  const query = useQuery([key, token], () => api.Option.getByType(type, token));

  return {
    options: query.isSuccess ? query.data.data.sort((a, b) => b.id - a.id) : [],
    loading: query.isLoading,
  };
};
