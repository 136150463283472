import { Button, Modal } from "antd";

function AddButton({
  name,
  children,
  formId,
  setVisible,
  visible,
  loading,
  setAddNext,
}) {
  const showModal = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal}>新增</Button>
      <Modal
        title={`新增${name}`}
        forceRender
        visible={visible}
        // getContainer={false}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="close" onClick={handleCancel}>
            取消
          </Button>,
          <Button
            form={formId}
            key="new"
            htmlType="submit"
            type="primary"
            loading={loading}
            onClick={() => setAddNext(true)}
          >
            存檔，新增下一筆
          </Button>,
          <Button
            form={formId}
            key="submit"
            htmlType="submit"
            type="primary"
            loading={loading}
            onClick={() => setAddNext(false)}
          >
            存檔，回列表
          </Button>,
        ]}
      >
        {children}
      </Modal>
    </>
  );
}

export default AddButton;
