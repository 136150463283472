import { Card, Typography } from "antd";

const { Text, Link, Paragraph } = Typography;

const InfoCard = () => {
  return (
    <Card style={{ marginBottom: 20 }} title="公告">
      <div style={{ marginBottom: 10 }}>
        <Paragraph>
          謝謝您使用生命教育音樂劇登錄系統，請您確實填寫所有欄位，有標註
          *號的欄位為必填欄位，若您的演出場次不只一場，請在填寫完畢後，按下「存檔，新增下一筆」的按鍵，系統會保留您已經填寫的資料，後來的場次您不需要全數重填，只需做細部修改即可。
          <br></br>
          <br></br>
          已完成登錄的場次，請您再次檢查是否有誤；若有錯誤，您可點選「編輯」以便更新資料；若沒有錯誤，
          <Text strong>請在「確定」欄位（框框）打勾，然後按下「送出」</Text>
          ，請務必打勾並送出，才算完成登錄。
          <br></br>
          <br></br>
          您也可以選擇透過上傳Excel表格的方式進行登錄，請先
          <Text strong>下載檔案範例</Text>(手機與電話欄位記得要選擇
          <Text strong>文字格式</Text>，否則會出錯)，並按照說明進行登錄，
          <Text strong>請不要更改檔名</Text>
          ，但可在後面加上日期，例如：SampleInput-2022.8.11.xlsx
          <br></br>
          <br></br>
          <Text strong>
            若遇已登錄之演出日期、時間、地點等更動，因攸關團隊保險權益，請務必email至lyc@rkf.org.tw通知相關同仁。
          </Text>
          <br></br>
          <br></br>
          若您有系統操作上的問題與保險相關問題，歡迎來電02-29080280#104林小姐，或email：lyc@rkf.org.tw
          洽詢，謝謝。
        </Paragraph>
      </div>
      <div>
        這裡可下載
        <Link
          href="https://docs.google.com/spreadsheets/d/1E616M_F-920HxaILmwkA2phVHYkQpt32/edit?usp=sharing&ouid=116074758041932205788&rtpof=true&sd=true"
          target="_blank"
        >
          範例 excel 檔
        </Link>
        <Text type="danger">
          (手機與電話欄位記得要選擇文字格式，否則會出錯，上傳正式資料前也請刪除範例資料)
        </Text>
      </div>
    </Card>
  );
};

export default InfoCard;
