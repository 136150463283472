import { Form, Input } from "antd";
import React from "react";

const PhoneFormItem = ({ required = false }) => {
  return (
    <Form.Item
      label="手機"
      name="mobile"
      rules={[
        {
          required: { required },
          pattern: /09[0-9]{2}-[0-9]{3}-[0-9]{3}$/,
        },
      ]}
    >
      <Input placeholder="格式寫法：0900-000-000"></Input>
    </Form.Item>
  );
};

export default PhoneFormItem;
