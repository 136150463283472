import { Box, Stack } from "@chakra-ui/react";
import PasswordForm from "../components/user/PasswordForm";
import ProfileForm from "../components/user/ProfileForm";

const SettingPage = () => {
  return (
    <>
      <Stack spacing={8} direction={{ md: "column", lg: "row" }}>
        <Box flex="1">
          <ProfileForm />
        </Box>
        <Box flex="1">
          <PasswordForm />
        </Box>
      </Stack>
    </>
  );
};

export default SettingPage;
