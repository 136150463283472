import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ConfigProvider } from "antd";
import zhTW from "antd/lib/locale/zh_TW";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.less";
import AuthProvider from "./components/AuthContext";
import DefaultLayout from "./DefaultLayout";
import Login from "./pages/Login";
import PublicRecordPage from "./pages/PublicRecordPage";
import RecoverPassword from "./pages/RecoverPassword";
import ResetPassword from "./pages/ResetPassword";
import Signup from "./pages/Signup";

const queryClient = new QueryClient();

const theme = extendTheme({
  colors: {
    brand: {
      100: "#e6fffb",
      200: "#b5f5ec",
      300: "#87e8de",
      400: "#5cdbd3",
      500: "#36cfc9",
      600: "#13c2c2",
      700: "#08979c",
      800: "#006d75",
      900: "#00474f",
    },
  },
});

function App() {
  return (
    <ConfigProvider locale={zhTW}>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AuthProvider>
              <Switch>
                <Route
                  exact
                  path="/login"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/signup"
                  render={(props) => <Signup {...props} />}
                />
                <Route
                  path="/reset_password"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/recover_password"
                  render={(props) => <RecoverPassword {...props} />}
                />
                <Route exact path="/" render={() => <PublicRecordPage />} />
                <Route path="/dashboard">
                  <DefaultLayout />
                </Route>
              </Switch>
              <ReactQueryDevtools initialIsOpen={false} />
            </AuthProvider>
          </Router>
        </QueryClientProvider>
      </ChakraProvider>
    </ConfigProvider>
  );
}

export default App;
