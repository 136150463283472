import { Box, Flex, Heading, Stack } from "@chakra-ui/react";
import { Typography } from "antd";
import loginstep from "../img/loginstep.png";

const { Text, Link } = Typography;

const InfoPage = () => {
  return (
    <Flex align={"center"} justify={"center"}>
      <Stack spacing={8} mx={"auto"} py={4} px={6}>
        <Heading size="lg">
          登錄步驟說明（因為表單欄位較寬，建議用電腦操作較為便利）
        </Heading>
        <img src={loginstep} alt="登錄步驟說明" />
        <Heading size="lg">演出場次登錄</Heading>
        <Box mb={3}>
          <Text fontSize={"lg"}>
            恭喜您完成生命教育音樂劇師資訓練，並準備將這份祝福帶入校園呈現給孩子們觀賞～
            因此，當您確認演出後，麻煩您務必協助我們登錄您的演出場次，因為：
            <li>
              我們將為演出的團隊投保 (公共意外責任險) 另附
              <Link
                href="https://drive.google.com/file/d/1FMwg-0Bd7bOP8BEM0Ile7ztBYJJu3G5o/view?usp=sharing"
                target="_blank"
              >
                公共意外責任險詳細說明
              </Link>
              及
              <Link
                href="https://drive.google.com/file/d/1bdprDC3ghdRklhOZ2Ffg4BCbdcoL2ZPX/view?usp=sharing"
                target="_blank"
              >
                理賠申請書
              </Link>
              供下載。
            </li>
            <li>開放觀賞的場次，可藉由網站介紹更多朋友就近觀賞。</li>
          </Text>
        </Box>
        <Heading size="lg">投保場次登錄時間</Heading>
        <Box mb={3}>
          <Text fontSize={"lg"}>
            恭喜您完成生命教育音樂劇師資訓練，並準備將這份祝福帶入校園呈現給孩子們觀賞～
            因此，當您確認演出後，麻煩您務必協助我們登錄您的演出場次，因為：
            <li>
              第一批次：113/10/9-14期間完成登錄並核可者，投保期間為：113/10/16-114/6/30演出之場次
            </li>
            <li>
              第二批次：113/10/15-31期間完成登錄並核可者，投保期間為：113/11/05-114/6/30演出之場次
            </li>
            <li>
              第三批次：113/11/01-10期間完成登錄並核可者，投保期間為：113/11/13-114/6/30演出之場次
            </li>
            <li>
              第四批次：113/11/11-20期間完成登錄並核可者，投保期間為：113/11/22-114/6/30演出之場次
            </li>
            <li>
              第五批次：113/11/21-30期間完成登錄並核可者，投保期間為：113/12/04-114/6/30演出之場次
            </li>
            <li>
              第六批次：113/12/01-10期間完成登錄並核可者，投保期間為：113/12/13-114/6/30演出之場次
            </li>
            <li>
              第七批次：113/12/11-20期間完成登錄並核可者，投保期間為：113/12/25-114/6/30演出之場次
            </li>
            <li>
              第八批次：113/12/21-31期間完成登錄並核可者，投保期間為：114/01/03-114/6/30演出之場次
            </li>
            <li>
              第九批次：114/01/01-10期間完成登錄並核可者，投保期間為：114/01/15-114/6/30演出之場次
            </li>
            <li>
              第十批次：114/01/11-20期間完成登錄並核可者，投保期間為：114/01/23-114/6/30演出之場次
            </li>
            <li>
              第十一批次：114/01/21-31期間完成登錄並核可者，投保期間為：114/02/4-114/6/30演出之場次
            </li>
            <li>
              第十二批次：114/02/01-10期間完成登錄並核可者，投保期間為：114/02/13-114/6/30演出之場次
            </li>
            <li>
              第十三批次：114/02/11-20期間完成登錄並核可者，投保期間為：114/02/24-114/6/30演出之場次
            </li>
            <li>
              第十四批次：1114/02/21-28期間完成登錄並核可者，投保期間為：114/03/05-114/6/30演出之場次
            </li>
            <li>
              第十五批次：114/03/01-10期間完成登錄並核可者，投保期間為：114/03/13-114/6/30演出之場次
            </li>
            <li>
              第十六批次：114/03/11-20期間完成登錄並核可者，投保期間為：114/03/24-114/6/30演出之場次
            </li>
            <li>
              第十七批次：114/03/21-31期間完成登錄並核可者，投保期間為：114/04/02-114/6/30演出之場次
            </li>
            <li>
              第十八批次：114/04/01-10期間完成登錄並核可者，投保期間為：114/04/14-114/6/30演出之場次
            </li>
            <li>
              第十九批次：114/04/11-20期間完成登錄並核可者，投保期間為：114/04/23-114/6/30演出之場次
            </li>
            <li>
              第十九批次：114/04/21-30期間完成登錄並核可者，投保期間為：114/05/05-114/6/30演出之場次
            </li>
            <li>
              第二十批次：114/05/01-10期間完成登錄並核可者，投保期間為：114/05/14-114/6/30演出之場次
            </li>
            <li>
              第二十一批次：114/05/11-20期間完成登錄並核可者，投保期間為：114/05/23-114/6/30演出之場次
            </li>
            <li>
              第二十二批次：114/05/21-31期間完成登錄並核可者，投保期間為：114/06/04-114/6/30演出之場次
            </li>
            <li>
              第二十三批次：114/06/01-10期間完成登錄並核可者，投保期間為：114/06/13-114/6/30演出之場次
            </li>
            <li>
              第二十四批次：114/06/11-20期間完成登錄並核可者，投保期間為：114/06/25-114/6/30演出之場次
            </li>
            為保障團隊的演出安全，請務必於期限內完成登錄並核可，逾時將無法投保，敬請配合，謝謝！
            <br></br>
            若有問題，請洽：02-2908-0280#104 林小姐<br></br>
            <br></br>
            祝福大家演出平安、喜樂、順利
          </Text>
        </Box>
      </Stack>
    </Flex>
  );
};

export default InfoPage;
