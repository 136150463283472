import axios from "axios";

const API_ROOT = process.env.REACT_APP_API_ROOT;
const TOKEN_KEY = "jwt";

function authHeaders(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

axios.interceptors.response.use(
  (response) => response,
  function (error) {
    if (error.response) {
      const { status } = error.response;
      if (status === 401 || status === 403) {
        window.localStorage.removeItem(TOKEN_KEY);
        window.sessionStorage.removeItem(TOKEN_KEY);
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

const requests = {
  del: (url, token) => axios.delete(`${API_ROOT}${url}`, authHeaders(token)),
  get: (url, token) => axios.get(`${API_ROOT}${url}`, authHeaders(token)),
  put: (url, body, token) =>
    axios.put(`${API_ROOT}${url}`, body, authHeaders(token)),
  post: (url, body, token) =>
    axios.post(`${API_ROOT}${url}`, body, authHeaders(token)),
};

const Auth = {
  login: (username, password) => {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return axios.post(`${API_ROOT}/v1/login/access-token`, formData);
  },
  signup: (email, password) =>
    axios.post(`${API_ROOT}/v1/user/open`, {
      email: email,
      password: password,
    }),
  recoverPassword: (email) =>
    axios.post(`${API_ROOT}/v1/password-recovery/${email}`),
  resetPassword: (token, newPassword) =>
    requests.post(
      "/v1/reset-password",
      { token: token, new_password: newPassword },
      token
    ),
};

const User = {
  me: (token) => requests.get("/v1/user/me", token),
  update: (data, token) => requests.put(`/v1/user/me`, data, token),
};

const Record = {
  all: () => requests.get(`/v1/record/public`),
  get: (token) => requests.get(`/v1/record/me`, token),
  create: (data, token) => requests.post(`/v1/record`, data, token),
  update: (id, data, token) => requests.put(`/v1/record/${id}`, data, token),
  delete: (id, token) => requests.del(`/v1/record/${id}`, token),
  upload: (data, token) => requests.post(`/v1/record/upload`, data, token),
};

const Option = {
  getByType: (type, token) => requests.get(`/v1/option/${type}`, token),
};

const api = {
  Auth,
  User,
  Record,
  Option,
};

export default api;
