import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import React from "react";

function UploadRecordButton({ onUpload }) {
  const upload = (request) => {
    onUpload.mutate({ request: request });
  };

  return (
    <Upload showUploadList={false} name="excel_file" customRequest={upload}>
      <Button icon={<UploadOutlined />}>上傳 Excel 檔案</Button>
    </Upload>
  );
}

export default UploadRecordButton;
