import InforCard from "../components/record/InforCard";
import RecordTable from "../components/record/RecordTable";

const RecordPage = () => {
  return (
    <>
      <InforCard />
      <RecordTable />
    </>
  );
};

export default RecordPage;
