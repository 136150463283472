export const getErrorMessage = (err) => {
  if (err.response) {
    return `${err.response.status} ${err.response.statusText}: ${err.response.data["detail"]}`;
  }
  return `${err}`;
};

export const isNumberRegx = /\d/;
export const specialCharacterRegx = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const dateFormat = "YYYY-MM-DD";
export const timeFormat = "HH:mm";
