import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as ReachLink, useHistory } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";

function Login() {
  const history = useHistory();
  // const location = useLocation();
  // const { from } = location.state || { from: { pathname: "/" } };
  const { login, loading } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const {
    handleSubmit,
    formState: { errors },
    register,
    formState,
  } = useForm();

  function onSubmit(values) {
    login(values.email, values.password, values.remember)
      .then(() => {
        // history.replace(from);
        history.replace("/dashboard");
      })
      .catch((err) => {
        setError(err);
      });
  }

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} minW={"400px"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>登入</Heading>
        </Stack>
        <Box textAlign="center">
          還沒有帳號？
          <Link as={ReachLink} to="/signup">
            註冊
          </Link>
        </Box>
        <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <FormControl id="email" isInvalid={errors.email}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input
                  type="email"
                  name="email"
                  placeholder="email"
                  {...register("email", { required: "請填寫正確的email" })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl id="password" isInvalid={errors.password}>
                <FormLabel htmlFor="password">密碼</FormLabel>
                <Input
                  type="password"
                  name="password"
                  placeholder="password"
                  {...register("password", { required: "請填寫密碼" })}
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <Checkbox id="remember" name="remember" {...register("remember")}>
                <Tooltip label="如果使用公用電腦請勿點選">記住我</Tooltip>
              </Checkbox>
              <Stack spacing={5}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  <Link as={ReachLink} to="recover_password">
                    忘記密碼
                  </Link>
                </Stack>
                <Button
                  color="white"
                  bg="brand.600"
                  isLoading={formState.isSubmitting || loading}
                  type="submit"
                  _hover={{
                    bg: "brand.500",
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  登入
                </Button>
                {error ? (
                  <Alert status="error">
                    <AlertIcon />
                    <AlertDescription>
                      {error.response.data["detail"]}
                    </AlertDescription>
                  </Alert>
                ) : null}
              </Stack>
            </Stack>
          </form>
        </Box>
        <Box textAlign="center">
          <Link as={ReachLink} to="/">
            回首頁
          </Link>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;
