import { Form, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { statusOptions } from "../../constants";
import { useRecord } from "../../hooks/record";
import { useUser } from "../../hooks/user";
import { dateFormat, timeFormat } from "../../utils";
import AddButton from "../AddButton";
import RecordForm from "./RecordForm";

const AddRecordButton = ({ locationOptions, playOptions, officeOptions }) => {
  const { user } = useUser();
  const { createRecord } = useRecord();
  const [visible, setVisible] = useState(false);
  const [addNext, setAddNext] = useState(false);
  const formId = "add_record";
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      contact_person: user ? user.name : "",
      ...user,
      play: playOptions.length > 0 ? playOptions[0].name : undefined,
      location_type:
        locationOptions.length > 0 ? locationOptions[0].name : undefined,
    });
  }, [playOptions, user, form, locationOptions, officeOptions]);

  const initialValues = {
    location: "",
    date: moment(),
    time: [moment("12:00", timeFormat), moment("14:00", timeFormat)],
    performers_num: 10,
    audiences_num: 100,
    is_public: true,
  };

  const onFinish = (values) => {
    const data = {
      ...values,
      address_city: values.address.city[0],
      address_site: values.address.city[1],
      address_street: values.address.street,
      date: values.date.format(dateFormat),
      start_time: values.time[0].format(timeFormat),
      end_time: values.time[1].format(timeFormat),
      status: statusOptions.pending,
    };
    createRecord.mutateAsync({ data: data }).then(() => {
      if (addNext) {
        message.info("存檔成功");
      } else {
        setVisible(false);
      }
    });
  };

  return (
    <>
      <AddButton
        name={"紀錄"}
        formId={formId}
        visible={visible}
        setVisible={setVisible}
        setAddNext={setAddNext}
        loading={createRecord.isLoading}
        children={
          <RecordForm
            formId={formId}
            form={form}
            initialValues={initialValues}
            onFinish={onFinish}
            locationOptions={locationOptions}
            playOptions={playOptions}
            officeOptions={officeOptions}
          />
        }
      />
    </>
  );
};

export default AddRecordButton;
