import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useUser = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);

  const me = useQuery(["me", { token }], () => api.User.me(token));

  const update = useMutation(({ data }) => api.User.update(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("me");
    },
  });

  return {
    user: me.isSuccess ? me.data.data : undefined,
    loading: me.isLoading,
    updateUser: update,
  };
};
