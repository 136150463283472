import { Button, message, Space, Table, Tag } from "antd";
import { useState } from "react";
import { statusOptions } from "../../constants";
import { useOptions } from "../../hooks/options";
import { useRecord } from "../../hooks/record";
import DeleteButton from "../DeleteButton";
import AddRecordButton from "./AddRecordButton";
import EditRecordButton from "./EditRecordButton";
import UploadRecordButton from "./UploadRecordButton";

const RecordTable = () => {
  const { records, loading, updateRecord, deleteRecord, uploadRecord } =
    useRecord();
  const { options: locationOptions } = useOptions({ type: "location_type" });
  const { options: playOptions } = useOptions({ type: "play" });
  const { options: officeOptions } = useOptions({ type: "office" });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const getOptions = (arr, key) => {
    return [...new Set(arr.map((d) => d[key]))]
      .filter((d) => (d !== null) & (d !== ""))
      .sort()
      .map((d) => {
        return { text: d, value: d };
      });
  };

  const onFilter = (key) => {
    return (value, record) => {
      if (record[key]) {
        return record[key] === value;
      }
    };
  };

  const office = getOptions(records, "office");
  const troupe = getOptions(records, "troupe");
  const play = getOptions(records, "play");
  const location_type = getOptions(records, "location_type");

  const data = records.map((d, i) => {
    d.key = i;
    return d;
  });

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedData(data.filter((i) => selectedRowKeys.includes(i.key)));
  };

  const onSubmit = () => {
    message.info("正在送出中");
    Promise.all(
      selectedData.map((i) => {
        return updateRecord.mutateAsync({
          id: i.id,
          data: { status: statusOptions.send },
        });
      })
    ).then(() => {
      message.info("已送出");
      setSelectedRowKeys([]);
      setSelectedData([]);
    });
  };

  const columns = [
    { key: "id", dataIndex: "id", title: "No" },
    {
      key: "send_date",
      dataIndex: "send_date",
      title: "送出日期",
    },
    {
      key: "contact_person",
      title: "聯絡人",
      dataIndex: "contact_person",
    },
    { key: "email", dataIndex: "email", title: "email" },
    { key: "mobile", dataIndex: "mobile", title: "手機" },
    { key: "home_phone", dataIndex: "home_phone", title: "電話" },
    {
      key: "office",
      dataIndex: "office",
      title: "所屬區辦",
      filters: office,
      onFilter: onFilter("office"),
    },
    {
      key: "troupe",
      dataIndex: "troupe",
      title: "劇團名稱",
      filters: troupe,
      onFilter: onFilter("troupe"),
    },
    {
      key: "play",
      dataIndex: "play",
      title: "戲劇名稱",
      filters: play,
      onFilter: onFilter("play"),
    },
    { key: "location", dataIndex: "location", title: "地點" },
    {
      key: "location_type",
      dataIndex: "location_type",
      title: "地點類型",
      filters: location_type,
      onFilter: onFilter("location_type"),
    },
    {
      key: "address",
      title: "地址",
      render: (text, record) => {
        return (
          <div>
            {record.address_city}
            {record.address_site}
            {record.address_street}
          </div>
        );
      },
    },
    { key: "date", dataIndex: "date", title: "演出日期" },
    {
      key: "time",
      title: "演出時間",
      render: (text, record) => {
        return (
          <div>
            {record.start_time} ~ {record.end_time}
          </div>
        );
      },
    },
    { key: "performers_num", dataIndex: "performers_num", title: "演出人數" },
    { key: "audiences_num", dataIndex: "audiences_num", title: "預估觀眾人數" },
    {
      key: "is_public",
      title: "是否開放觀賞",
      render: (text, record) =>
        record.is_public ? <Tag color="blue">開放</Tag> : <Tag>不開放</Tag>,
      filters: [
        {
          text: "開放",
          value: true,
        },
        {
          text: "不開放",
          value: false,
        },
      ],
      onFilter: (value, record) => record.is_public === value,
    },
    {
      key: "status",
      title: "狀態",
      render: (text, record) => {
        let color = "red";
        switch (record.status) {
          case statusOptions.send:
            color = "gold";
            break;
          case statusOptions.checked:
            color = "blue";
            break;
          case statusOptions.returned:
            color = "red";
            break;
          case statusOptions.insured:
            color = "green";
            break;
          default:
            color = "";
            break;
        }
        return <Tag color={color}>{record.status}</Tag>;
      },
      filters: Object.values(statusOptions).map((d) => {
        return { text: d, value: d };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    { key: "note", dataIndex: "note", title: "使用者備註" },
    { key: "manager_response", dataIndex: "manager_response", title: "通知" },
    {
      key: "action",
      title: "操作",
      render: (text, record) => (
        <>
          {record.status === statusOptions.pending ||
          record.status === statusOptions.returned ? (
            <Space>
              <EditRecordButton
                record={record}
                locationOptions={locationOptions}
                playOptions={playOptions}
                officeOptions={officeOptions}
              />
              <DeleteButton
                title={`你確定要刪除場次「${record.id}」? `}
                onDelete={() => {
                  deleteRecord.mutate(record.id);
                }}
              />
            </Space>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={selectedRowKeys.length === 0}
          loading={loading}
          size="large"
        >
          送出
        </Button>
        <Space>
          <UploadRecordButton onUpload={uploadRecord} />
          <AddRecordButton
            locationOptions={locationOptions}
            playOptions={playOptions}
            officeOptions={officeOptions}
          />
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: (record) => ({
            disabled:
              record.status !== statusOptions.pending &&
              record.status !== statusOptions.returned,
          }),
        }}
        loading={loading}
        scroll={{ x: 536 }}
      ></Table>
    </>
  );
};

export default RecordTable;
