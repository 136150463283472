import { Button, Modal } from "antd";

function EditButton({ name, children, formId, setVisible, visible, loading }) {
  const showModal = (e) => {
    e.stopPropagation();
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button onClick={showModal} type="text" size="small">
        編輯
      </Button>
      <Modal
        title={`編輯${name}`}
        visible={visible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="close" onClick={handleCancel}>
            取消
          </Button>,
          <Button
            form={formId}
            key="submit"
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            存檔
          </Button>,
        ]}
      >
        {children}
      </Modal>
    </>
  );
}

export default EditButton;
