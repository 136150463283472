import { message } from "antd";
import { useContext } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../api";
import { AuthContext } from "../components/AuthContext";

export const useRecord = () => {
  const queryClient = useQueryClient();
  const { token } = useContext(AuthContext);
  const query = useQuery(["records", token], () => api.Record.get(token));

  const create = useMutation(({ data }) => api.Record.create(data, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("records");
    },
  });

  const update = useMutation(
    ({ id, data }) => api.Record.update(id, data, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("records");
      },
    }
  );

  const del = useMutation((id) => api.Record.delete(id, token), {
    onSuccess: () => {
      queryClient.invalidateQueries("records");
    },
  });

  const upload = useMutation(
    ({ request }) => {
      const { file, filename } = request;
      const formData = new FormData();
      formData.append(filename, file);
      return api.Record.upload(formData, token);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries("records");
        message.success(`成功上傳 ${variables.request.file.name}`);
      },
      onError: (error, variables) => {
        message.error(
          `${variables.request.file.name} 上傳失敗. ${error.response.data["detail"]}`
        );
      },
    }
  );

  return {
    records: query.isSuccess ? query.data.data.sort((a, b) => b.id - a.id) : [],
    loading: query.isLoading,
    createRecord: create,
    updateRecord: update,
    deleteRecord: del,
    uploadRecord: upload,
  };
};
