import { useQuery } from "react-query";
import api from "../api";

export const useRecords = () => {
  const query = useQuery(["public_records"], () => api.Record.all());

  return {
    records: query.isSuccess ? query.data.data.sort((a, b) => b.id - a.id) : [],
    loading: query.isLoading,
  };
};
