import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";

const { confirm } = Modal;

const DeleteButton = ({ title, onDelete }) => {
  const showConfirm = (title) => {
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: onDelete,
    });
  };
  return (
    <Button
      key="delete"
      type="text"
      size="small"
      onClick={() => showConfirm(title)}
    >
      刪除
    </Button>
  );
};

export default DeleteButton;
