import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Container,
  Flex,
  Icon,
  IconButton,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext } from "react";
import { Link as ReachLink, useHistory } from "react-router-dom";
import { AuthContext } from "./AuthContext";

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();
  const { logout, token } = useContext(AuthContext);
  const history = useHistory();

  const navItems = [
    {
      label: "場次列表",
      href: "/",
    },
    {
      label: "場次登錄",
      href: "/dashboard/records",
    },
  ];

  const handleClickLogout = () => {
    logout();
    history.push("/");
  };

  return (
    <Box>
      <Box
        minH={"60px"}
        py={{ base: 4 }}
        px={{ base: 10 }}
        borderBottom={1}
        boxShadow="sm"
        borderStyle={"solid"}
        borderColor={"gray.200"}
      >
        <Container maxW="container.xl">
          <Flex bg={"white"} color={"gray.600"} align={"center"}>
            {token ? (
              <Flex
                flex={{ base: 1, md: "auto" }}
                ml={{ base: -2 }}
                display={{ base: "flex", md: "none" }}
              >
                <IconButton
                  onClick={onToggle}
                  icon={
                    isOpen ? (
                      <CloseIcon w={3} h={3} />
                    ) : (
                      <HamburgerIcon w={5} h={5} />
                    )
                  }
                  variant={"ghost"}
                  aria-label={"Toggle Navigation"}
                />
              </Flex>
            ) : null}
            <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
              <Text
                textAlign={useBreakpointValue({ base: "center", md: "left" })}
                fontFamily={"heading"}
                color="brand.600"
                as={ReachLink}
                fontSize="lg"
                fontWeight={500}
                to="/dashboard"
              >
                {process.env.REACT_APP_NAME}
              </Text>

              {token ? (
                <Flex display={{ base: "none", md: "flex" }} ml={10}>
                  <DesktopNav navItems={navItems} />
                </Flex>
              ) : null}
            </Flex>

            <Stack
              flex={{ base: 1, md: 0 }}
              justify={"flex-end"}
              direction={"row"}
              spacing={6}
            >
              {token ? (
                <>
                  <ReachLink to="/dashboard/setting">
                    <Button
                      fontWeight={500}
                      variant={"ghost"}
                      display={{ base: "none", md: "block" }}
                    >
                      設定
                    </Button>
                  </ReachLink>
                  <Button
                    onClick={handleClickLogout}
                    fontWeight={500}
                    variant={"ghost"}
                  >
                    登出
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    as={ReachLink}
                    fontWeight={500}
                    variant={"ghost"}
                    to="/login"
                  >
                    登入
                  </Button>
                  <Button
                    display={{ base: "none", md: "inline-flex" }}
                    fontSize={"sm"}
                    fontWeight={500}
                    color={"white"}
                    bg={"brand.600"}
                    href={"#"}
                    as={ReachLink}
                    to="/signup"
                    _hover={{
                      bg: "brand.500",
                      color: "white",
                      textDecoration: "none",
                    }}
                  >
                    註冊
                  </Button>
                </>
              )}
            </Stack>
          </Flex>
        </Container>
      </Box>

      {token ? (
        <Collapse in={isOpen} animateOpacity>
          <MobileNav navItems={navItems} />
        </Collapse>
      ) : null}
    </Box>
  );
}

const DesktopNav = ({ navItems }) => {
  return (
    <Stack direction={"row"} spacing={4}>
      {navItems.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                as={ReachLink}
                p={3}
                to={navItem.href ?? "#"}
                fontSize={"md"}
                fontWeight={500}
                color={"gray.600"}
                _hover={{
                  textDecoration: "none",
                  color: "gray.800",
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && navItem.children.length > 0 && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={"white"}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
  return (
    <Link
      as={ReachLink}
      to={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: "brand.100" }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "brand.600" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"md"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"brand.600"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = ({ navItems }) => {
  return (
    <Stack bg={"white"} p={4} display={{ md: "none" }}>
      <Flex
        py={2}
        as={ReachLink}
        to={"/dashboard/setting"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={500} color={"gray.600"}>
          設定
        </Text>
      </Flex>
      {navItems.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={ReachLink}
        to={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text fontWeight={500} color={"gray.600"}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={"gray.200"}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} as={ReachLink} to={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
