import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  TimePicker,
} from "antd";
import AddressFormItem from "../AddressFormItem";
import PhoneFormItem from "../PhoneFormItem";

const { Option } = Select;
const { TextArea } = Input;

const RecordForm = ({
  onFinish,
  formId,
  form,
  initialValues,
  locationOptions,
  playOptions,
  officeOptions,
}) => {
  return (
    <Form
      id={formId}
      form={form}
      preserve={false}
      resetFields={false}
      layout={"vertical"}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      <Form.Item
        label="聯絡人"
        name="contact_person"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="email"
        name="email"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <PhoneFormItem required={true} />
      <Form.Item label="電話" name="home_phone">
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="所屬區辦"
        name="office"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select>
          {officeOptions.map((i) => (
            <Option value={i.name} key={i.name}></Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="劇團名稱"
        name="troupe"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="戲劇名稱"
        name="play"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select>
          {playOptions.map((i) => (
            <Option value={i.name} key={i.name}></Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="地點"
        name="location"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input></Input>
      </Form.Item>
      <Form.Item
        label="地點類別"
        name="location_type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select>
          {locationOptions.map((i) => (
            <Option value={i.name} key={i.name}></Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="演出日期"
        name="date"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <DatePicker />
      </Form.Item>
      <Form.Item
        label="演出時間"
        name="time"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TimePicker.RangePicker />
      </Form.Item>
      <Form.Item
        label="演出人數"
        name="performers_num"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber min={1} max={100}></InputNumber>
      </Form.Item>
      <Form.Item
        label="預估觀賞人數"
        name="audiences_num"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber min={0}></InputNumber>
      </Form.Item>
      <AddressFormItem required={true} />
      <Form.Item label="是否開放觀賞" name="is_public" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="備註" name="note">
        <TextArea></TextArea>
      </Form.Item>
    </Form>
  );
};

export default RecordForm;
