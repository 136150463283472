import { Box, Container } from "@chakra-ui/layout";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NavBar from "./components/NavBar";
import ProtectedRoute from "./components/ProtectedRoute";
import InfoPage from "./pages/InfoPage";
import RecordPage from "./pages/RecordPage";
import SettingPage from "./pages/SettingPage";

function DefaultLayout() {
  const { path } = useRouteMatch();
  return (
    <>
      <NavBar />
      <Box px={{ base: 10 }} py={{ base: 10 }}>
        <Container maxW="container.xl">
          <Switch>
            <Route exact key="dashboard" path={`${path}`}>
              <InfoPage />
            </Route>
            <ProtectedRoute exact key="records" path={`${path}/records`}>
              <RecordPage />
            </ProtectedRoute>
            <ProtectedRoute exact key="setting" path={`${path}/setting`}>
              <SettingPage />
            </ProtectedRoute>
          </Switch>
        </Container>
      </Box>
    </>
  );
}

export default DefaultLayout;
