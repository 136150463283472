import moment from "moment";
import React, { useState } from "react";
import { useRecord } from "../../hooks/record";
import { dateFormat, timeFormat } from "../../utils";
import EditButton from "../EditButton";
import RecordForm from "./RecordForm";

const EditRecordButton = ({
  record,
  locationOptions,
  playOptions,
  officeOptions,
}) => {
  const { updateRecord } = useRecord();
  const [visible, setVisible] = useState(false);
  const formId = "edit_record";
  const initialValues = {
    ...record,
    date: moment(record.date, dateFormat),
    time: [
      moment(record.start_time, timeFormat),
      moment(record.end_time, timeFormat),
    ],
    address: {
      city: [record.address_city, record.address_site],
      street: record.address_street,
    },
  };
  const onFinish = (values) => {
    const data = {
      ...values,
      address_city: values.address.city[0],
      address_site: values.address.city[1],
      address_street: values.address.street,
      date: values.date.format(dateFormat),
      start_time: values.time[0].format(timeFormat),
      end_time: values.time[1].format(timeFormat),
    };

    updateRecord.mutateAsync({ id: record.id, data: data }).then(() => {
      setVisible(false);
    });
  };

  return (
    <EditButton
      name={"紀錄"}
      formId={formId}
      visible={visible}
      setVisible={setVisible}
      loading={updateRecord.isLoading}
      children={
        <RecordForm
          formId={formId}
          initialValues={initialValues}
          onFinish={onFinish}
          locationOptions={locationOptions}
          playOptions={playOptions}
          officeOptions={officeOptions}
        />
      }
    />
  );
};

export default EditRecordButton;
